import { MarketCountryCode } from '@backmarket/http-api/src/api-models/MarketCountryCode'
import type { GetBuyBackAddressResponse } from '@backmarket/http-api/src/api-specs-buyback/customer/getAddress'
import { Country } from '@backmarket/http-api/src/standards/Country'
import type { Input } from '@backmarket/nuxt-layer-buyback/components/FormGenerator/FormGenerator.types'
import { useBuybackConfig } from '@backmarket/nuxt-layer-buyback/composables/config/useBuybackConfig'
import phoneTranslations from '@backmarket/nuxt-module-address/phone.translations'
import { getShippingStates } from '@backmarket/nuxt-module-address/utils/state/getShippingStates'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { insertIf } from '@backmarket/utils/collection/insertIf'
import { isAgeLegal } from '@backmarket/utils/date/isAgeLegal'
import { FORM_VALID, maxLength, minLength, required } from '@ds/components/Form'
import {
  getCountryCodeFromPhoneNumber,
  validPhoneNumber,
} from '@ds/components/InputPhone'

import { useRouteLocationWithLocale } from '~/composables/useRouteLocationWithLocale'
import { CMS } from '~/scopes/cms/routes-names'
import { LEGAL_PAGE_NAME } from '~/scopes/legal/constants'

import translations from '../pages/Address.translations'

const MIN_AGE = 18
const MIN_YEAR = 1901

export const useGenerateAddressForm = (
  address: GetBuyBackAddressResponse | null,
): Array<Input> => {
  const i18n = useI18n()
  const { market } = useMarketplace()
  const { countryCode } = market
  const injectLocale = useRouteLocationWithLocale()

  const buybackConfig = useBuybackConfig()
  const internationalNumber =
    address?.phone && address?.country_dial_in_code
      ? `${address?.country_dial_in_code}${address?.phone}`
      : ''
  const countryCodeFromPhoneNumber =
    getCountryCodeFromPhoneNumber(internationalNumber)

  return [
    /* DATE OF BIRTH */
    ...insertIf(Boolean(buybackConfig?.kyc?.birthDate), [
      {
        id: 'birthdate',
        cols: 6,
        type: 'date',
        label: i18n(translations.formBirthLabel),
        description: i18n(translations.formBirthDescription),
        validation: [
          function validateBirthDate(value: string) {
            if (!value) {
              return FORM_VALID
            }

            const parsed = Date.parse(value)
            const birthdate = new Date(parsed)
            const isYoungEnough = birthdate.getFullYear() > MIN_YEAR
            const isOldEnough = isAgeLegal({
              legalAge: MIN_AGE,
              birthdate,
            })

            return isOldEnough && isYoungEnough
              ? FORM_VALID
              : i18n(translations.formBirthError)
          },
          required(i18n(translations.formMandatoryError)),
        ],
        value: address?.birthdate || '',
      },
    ]),

    /* STREET */
    ...insertIf(Boolean(buybackConfig?.kyc?.address?.street), [
      {
        id: 'street',
        cols: 6,
        type: 'address-autocomplete',
        label: i18n(translations.formAddressLabel),
        description: i18n(translations.formAddressDescription),
        validation: [required(i18n(translations.formMandatoryError))],
        value: address?.street || '',
      },
    ]),

    /* STREET 2 */
    ...insertIf(Boolean(buybackConfig?.kyc?.address?.street2), [
      {
        id: 'street2',
        cols: 6,
        type: 'text',
        label: i18n(translations.formAddressComplementLabel),
        description: i18n(translations.formAddressComplementDescription),
        validation: [],
        value: address?.street2 || '',
      },
    ]),

    /* CITY */
    ...insertIf(Boolean(buybackConfig?.kyc?.address?.city), [
      {
        id: 'city',
        cols: {
          mobile: 6,
          desktop: 3,
        },
        type: 'text',
        label: i18n(translations.formCityLabel),
        description: i18n(translations.formCityDescription),
        validation: [required(i18n(translations.formMandatoryError))],
        value: address?.city || '',
      },
    ]),

    /* POSTAL CODE */
    ...insertIf(Boolean(buybackConfig?.kyc?.address?.postalCode), [
      {
        id: 'postalCode',
        cols: {
          mobile: 6,
          desktop: 3,
        },
        type: 'text',
        label: i18n(translations.formZipLabel),
        description: i18n(translations.formZipDescription),
        validation: [
          required(i18n(translations.formMandatoryError)),
          maxLength(
            buybackConfig.kyc.postalCodeLength.max,
            i18n(translations.postalCodeLengthError, {
              length: buybackConfig.kyc.postalCodeLength.max,
            }),
          ),
          minLength(
            buybackConfig.kyc.postalCodeLength.min,
            i18n(translations.postalCodeLengthError, {
              length: buybackConfig.kyc.postalCodeLength.min,
            }),
          ),
        ],
        value: address?.postal_code || '',
      },
    ]),

    /* STATE OR PROVINCE */
    ...insertIf(Boolean(buybackConfig?.kyc?.address?.stateOrProvince), [
      {
        type: 'select',
        cols: {
          mobile: 6,
          desktop: 3,
        },
        id: 'stateOrProvince',
        disabled: false,
        label: i18n(translations.formStateLabel),
        validation: [required(i18n(translations.formMandatoryError))],
        value: address?.state_or_province || '',
        options: getShippingStates(Country[countryCode]),
      },
    ]),

    /* COUNTRY */
    ...insertIf(Boolean(buybackConfig?.kyc?.address?.country), [
      {
        id: 'country',
        cols: {
          mobile: 6,
          desktop: 3,
        },
        type: 'select',
        label: i18n(translations.formCountryLabel),
        options: [
          {
            value: countryCode,
            label: i18n.country(countryCode),
          },
        ],
        validation: [required(i18n(translations.formMandatoryError))],
        disabled: true,
        value: countryCode,
      },
    ]),

    /* PHONE */
    ...insertIf(Boolean(buybackConfig?.kyc?.phone), [
      {
        id: 'phone',
        cols:
          countryCode === MarketCountryCode.US
            ? 6
            : {
                mobile: 6,
                desktop: 3,
              },
        type: 'phone',
        country: countryCodeFromPhoneNumber,
        label: i18n(translations.formPhoneLabel),
        description: i18n(translations.formPhoneDescription),
        disabled: false,
        validation: [
          required(i18n(translations.formMandatoryError)),
          validPhoneNumber(i18n(phoneTranslations.invalid)),
        ],
        value: internationalNumber,
      },
    ]),

    /* LEGAL */
    {
      id: 'legal',
      cols: 6,
      type: 'checkbox',
      disabled: false,
      label: '',
      formattedMessage: {
        id: 'link',
        definition: translations.formLegalMessage,
        label: i18n(translations.formCgr),
        route: injectLocale({
          name: CMS.LEGAL_PAGE,
          params: {
            pageName: LEGAL_PAGE_NAME.BUYBACK,
          },
        }),
      },
      description: '',
      validation: [
        (value) => (value ? FORM_VALID : i18n(translations.formMandatoryError)),
      ],
      value: false,
    },
  ] as Array<Input>
}
