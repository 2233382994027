import { createHttpEndpoint } from '../../utils'

/**
 * Get customer return Address
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/buyback-bm-api/definition#tag/Customer/operation/getCustomerAddress}
 */

// this is slightly different from address model
export interface GetBuyBackAddressResponse {
  first_name: string
  last_name: string
  company?: string
  street: string
  street2?: string
  postal_code: string
  city: string
  country: string
  country_dial_in_code?: string
  phone: string
  birthdate: string
  nationality: string
  state_or_province?: string
  public_id: string
}

export const getBuyBackAddress = createHttpEndpoint<GetBuyBackAddressResponse>({
  method: 'GET',
  operationId: 'getBuyBackAddress',
  path: '/bm/buyback/v1/customer/address',
})
